import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Hidden,
  TextField,
  Typography,
  SnackbarContent,
} from "@material-ui/core";
import ContactUsBackground from "../../images/contact-us-background.png";
import ColorButton from "../../components/ColorButton";
import jwt from "jsonwebtoken";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3' 

const styles = theme => ({
  root: {
    width: "100vw",
    height: "800px",
    [theme.breakpoints.only("sm")]: {
      height: "900px",
    },
    [theme.breakpoints.only("xs")]: {
      height: "850px",
    },
    flexGrow: 1,
    spacing: 0,
    justify: "space-around",
    textAlign: "center",
    alignItems: "center",
    marginTop: theme.spacing(5),
    position: "relative",
    overflow: "hidden",
  },
  backgroundImg: {
    position: "absolute",
    height: "100%",
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      height: "110%",
    },
    backgroundImage: `linear-gradient(rgb(24, 26, 52,0.95), rgb(24, 26, 52,0.95)), url(${ContactUsBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundAttachment: "fixed",
    "@supports (-webkit-touch-callout: none)": {
      backgroundAttachment: "scroll",
    },
    transform: "translateZ(-10px) scale(1.1)",
  },
  container: {
    position: "absolute",
    width: "100%",
    left: "50%",
    transform: "translate(-50%, 0)",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(15),
      paddingRight: theme.spacing(15),
      paddingTop: theme.spacing(5),
    },
  },
  title: {
    marginTop: "10vh",
    fontWeight: "bold",
    color: "#EDF4F5",
    marginBottom: "7vh",
    textAlign: "center",
  },
  heading: {
    color: "#EDF4F5",
  },
  description: {
    color: "#80818D",
  },
  contact: {
    textAlign: "left",
  },
  divider: {
    backgroundColor: "#80818D",
    width: "0.4px",
    height: "100%",
  },
  form: {
    textAlign: "left",
  },
  credit: {
    position: "absolute",
    bottom: "13%",
    [theme.breakpoints.up("sm")]: {
      bottom: "5%",
    },
    color: "#80818D",
  },
});
const CssTextField = withStyles({
  root: {
    "& label": {
      color: "#80818D",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      color: "white",
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);
const OtherWorkshopContactUs = props => {
  const classes = props.classes;
  const [values, setValues] = React.useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [error, setError] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    isSuccess: true,
    message: "",
  });
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const isValidEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const { executeRecaptcha } = useGoogleReCaptcha();
  const submit = async () => {
    if (
      values.name == "" ||
      values.phone == "" ||
      !isValidEmail(values.email)
    ) {
      setError(true);
      setSnackbar({
        open: true,
        isSuccess: false,
        message: "please verify your inputs",
      });
    } else {
      if (!executeRecaptcha) {
        setSnackbar({
          open: true,
          isSuccess: false,
          message: "Unable to process your request",
        });
        return;
      }
      const captchaResult = await executeRecaptcha('OtherWorkshopContactUs');
      sendMessage(captchaResult);
    }
  };
  const sendMessage = (captchaToken) => {
    setError(false);
    setSnackbar({ ...snackbar, open: false });
    let body = { name: "", phone: "", email: "", message: "", captchaToken: "" };
    body.name = values.name;
    body.phone = values.phone;
    body.email = values.email;
    body.message = values.message;
    body.captchaToken = captchaToken
    let protocol = window.location.protocol;
    let url = protocol + "//" + window.location.host + "/.netlify/functions/contact-us";
    // let url = "http://localhost:9000/contact-us";
    const token = jwt.sign(body, process.env.GATSBY_FUNCTION_API_SECRET, {
      expiresIn: "180s",
    });
    fetch(url, {
      // mode: 'no-cors',
      body: JSON.stringify(body),
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(response => {
        if (response.status == 200) {
          setError(false);
          setSnackbar({
            open: true,
            isSuccess: true,
            message: "You request has been sent!",
          });
        } else {
          setError(true);
          setSnackbar({
            open: true,
            isSuccess: false,
            message: "Failed to send your request. Please try again!",
          });
        }
      })
      .catch(error => {
        setError(true);
        setSnackbar({
          open: true,
          isSuccess: false,
          message: "Failed to send your request. Please try again!",
        });
      });
  };
  return (
    <section id="OtherWorkshopContactUs" className={classes.root}>
      <div className={classes.backgroundImg}>
        <Grid
          container
          spacing={0}
          direction="row"
          justify="center"
          alignItems="stretch"
          className={classes.credit}
        >
          <Grid item xs={12} sm={6}>
            <Typography gutterBottom variant="subtitle2" component="p">
              design & developed by Hoang Long
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography gutterBottom variant="subtitle2" component="p">
              icons provided by Freepik
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Container className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          Request For A Course
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="stretch"
        >
          <Grid item xs={12} md={4}>
            <Grid
              container
              spacing={3}
              direction="column"
              justify="center"
              alignItems="stretch"
            >
              <Grid item xs={12} className={classes.contact}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h5"
                  className={classes.heading}
                >
                  Email
                </Typography>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h6"
                  className={classes.description}
                >
                  cherngs@gmail.com
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.contact}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h5"
                  className={classes.heading}
                >
                  Phone Number
                </Typography>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h6"
                  className={classes.description}
                >
                  +65 9742 1299
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={1}>
              <div className={classes.divider}></div>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={7} className={classes.form}>
            <form noValidate autoComplete="off">
              <Grid
                container
                spacing={3}
                direction="column"
                justify="flex-start"
                alignItems="stretch"
              >
                <Grid item xs={12}>
                  <CssTextField
                    label="Full Name"
                    required
                    fullWidth
                    value={values.name}
                    onChange={handleChange("name")}
                    variant="outlined"
                    error={error}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    label="Phone Number"
                    required
                    fullWidth
                    value={values.phone}
                    onChange={handleChange("phone")}
                    variant="outlined"
                    error={error}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    type="email"
                    label="Your Email"
                    required
                    fullWidth
                    value={values.email}
                    onChange={handleChange("email")}
                    variant="outlined"
                    error={error}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    label="Your Message"
                    fullWidth
                    multiline
                    rowsMax={4}
                    value={values.message}
                    onChange={handleChange("message")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <SnackbarContent
                    message={snackbar.message}
                    style={{
                      display: snackbar.open ? "block" : "none",
                      backgroundColor: snackbar.isSuccess
                        ? "#4caf50"
                        : "#f44336",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ColorButton
                    variant="outlined"
                    size="medium"
                    onClick={() => {
                      submit();
                    }}
                  >
                    Submit
                  </ColorButton>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default withStyles(styles)(OtherWorkshopContactUs);
