import React from "react";
import "../styles/index.css";
import Layout from "../components/layout";
import SEO from "../components/seo";
import OtherWorkshopIntro from "../sections/other-workshop/other-workshop-intro";
import OtherWorkshopCourses from "../sections/other-workshop/other-workshop-courses";
import OtherWorkshopContactUs from "../sections/other-workshop/other-workshop-contact";

const OtherWorkshopPage = () => (
  <Layout>
    <SEO title="Cherng's Listening Ear" />
    <OtherWorkshopIntro />
    <OtherWorkshopCourses />
    <OtherWorkshopContactUs />
  </Layout>
);

export default OtherWorkshopPage;
