import React from "react";
import { withStyles } from "@material-ui/core/styles";
import RelationshipCourseImage from "../../images/relationship-course-image.jpg";
import ChristianCourseImage from "../../images/christian-course-image.jpg";
import ChildrenCourseImage from "../../images/children-course-image.jpg";
import {
  Typography,
  Grid,
  Card,
  Container,
  CardContent,
  CardMedia,
} from "@material-ui/core";

const styles = theme => ({
  root: {
    width: "100vw",
    height: "auto",
    flexGrow: 1,
    spacing: 0,
    justify: "space-around",
    textAlign: "center",
    marginTop: "35px",
    alignItems: "center",
    backgroundColor: "#EDF4F545",
  },
  container: {
    width: "80%",
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    color: "#181A34",
    marginBottom: "5%",
  },
  imgContainer: {
    height: 160,
    position: "relative",
  },
  card: {
    height: "100%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
});

const OtherWorkshopCourses = props => {
  return (
    <section id="OtherWorkshopCourses" className={props.classes.root}>
      <Container className={props.classes.container}>
        <Typography variant="h2" className={props.classes.title}>
          Courses
        </Typography>
        <Grid
          container
          spacing={5}
          direction="row"
          justify="center"
          alignItems="stretch"
        >
          <Grid item xs={12} md={4}>
            <Card className={props.classes.card}>
              <CardMedia
                className={props.classes.media}
                image={RelationshipCourseImage}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Relationship Courses
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  Marriage Preparation Courses
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  (Since 2012)
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  KKMC WSCS 'Hi-Tea Talk' on Transformational Relationships
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  (2017)
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={props.classes.card}>
              <CardMedia
                className={props.classes.media}
                image={ChristianCourseImage}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Christian Courses
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  The 'Upper Room Experience' Seminar on Passover
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  (Since 2011)
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  'By This Name' Course
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  (Since 2012)
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  'Disciple 1' Course
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  (2016 to 2017)
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={props.classes.card}>
              <CardMedia
                className={props.classes.media}
                image={ChildrenCourseImage}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Courses for Children
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  Addiction
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  Anger Management
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  Being Responsible
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  Friendship
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  Puberty
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  Social Etiquette
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  The Art of Respect
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  The True Meaning of Christmas
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default withStyles(styles)(OtherWorkshopCourses);
